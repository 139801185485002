import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppContext from '../Store/AppContext'
import RouteSuspense from './RouteSuspense'
import { PrivateRoute } from './PrivateRoute'
import { Login } from 'containers/AccountModule/Login'
import { ForgotPassword } from 'containers/AccountModule/ForgotPassword'
import { ActivatedUser } from '../../AccountModule/ActivatedUser'
import { CreatePassword } from 'containers/AccountModule/CreatePassword'
import { ResetPassword } from 'containers/AccountModule/ResetPassword'
import { Logout } from 'containers/AccountModule/Logout'
import {
  useGetHotelPermissionQuery,
  useGetRolePermissionQuery,
} from 'generated/graphql'
import { client } from 'client'
import localForage from 'localforage'
import SystemAdminRoutes from 'containers/SystemAdminModule/SystemAdminRoutes'
import { QRCheckWrapper } from '../QRCheckWrapper'
const Routes = () => {
  const { globalState } = useContext(AppContext as any)
  /**
   *  Pentest - MISSING FUNCTION LEVEL ACCESS CONTROL (MFLAC)
   * solution -  Check isSuperUser then incluide system admind routes
   */

  const IsSuperUserRoutes = JSON.parse(localStorage.getItem('LoggedInUser'))
    ?.superUser
    ? [...SystemAdminRoutes, ...globalState.routes]
    : globalState.routes

  return (
    <Switch>
      <Route path="/user/activate/:token">
        <ActivatedUser />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/user/reset-password/:token">
        <ResetPassword />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/user/create-password/:token">
        <CreatePassword />
      </Route>
      {IsSuperUserRoutes.map((el, index) => {
        return (
          <PrivateRoute key={index} {...el.props}>
             <QRCheckWrapper>
            <RouteSuspense>{el.component}</RouteSuspense>
            </QRCheckWrapper>
          </PrivateRoute>
        )
      })}
    </Switch>
  )
}

export default Routes
