import React from 'react'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share'
import { b64toBlob } from 'containers/FrontDeskModule/Common'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { Email } from '@material-ui/icons'
import { Button } from '@material-ui/core'
interface ShareType {
  simple: boolean
  setSimple: any
  Title: string
  ShareTitle: string
  URL: string
  EmailShare?: boolean
  setSimpleEmail?: any
}
const dateFormat = require('dateformat')

export const htmlConverter = ({ FormContent, Name }) => {
  var blob = new Blob([FormContent], { type: 'text/html' })
  blob['name'] = Name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}

const ShareDialog = (props: ShareType) => {
  const {
    simple,
    setSimple,
    Title,
    URL,
    ShareTitle,
    EmailShare,
    setSimpleEmail,
  } = props
  return (
    <StandardDialog
      fullWidth={true}
      open={simple}
      onClose={() => setSimple(false)}
      sections={{
        header: {
          title: `${Title}`,
        },
        body: () => (
          <>
            <div className="content-container">
              <WhatsappShareButton title={ShareTitle} url={URL}>
                <WhatsappIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </WhatsappShareButton>
              <FacebookShareButton title={ShareTitle} url={URL}>
                <FacebookIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </FacebookShareButton>
              <TelegramShareButton title={ShareTitle} url={URL}>
                <TelegramIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </TelegramShareButton>
              <TwitterShareButton title={ShareTitle} url={URL}>
                <TwitterIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </TwitterShareButton>

              {EmailShare && (
                <Button onClick={() => setSimpleEmail(true)}>
                  <MailOutlineIcon
                    style={{ marginRight: '5px', fontSize: 40 }}

                    // href={`mailto:${EmailShare}?subject=&body=Booking Confirmation Letter`}
                  />
                </Button>
              )}
            </div>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setSimple(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}

const ConvertFormToURL = ({ FormContent, Name }) => {
  var contentType = 'text/html'
  const blob = new Blob([FormContent], { type: contentType })
  // var encodedStringBtoA = btoa(FormContent)
  // const blob = b64toBlob(encodedStringBtoA, contentType, 512)
  blob['name'] = Name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}
export { ShareDialog as default, ConvertFormToURL }
