import React, { lazy } from 'react'
import { DocumentType, FrontDeskType } from '../Common'
const Document = lazy(() =>
  import('./Document').then(module => ({
    default: module.Document,
  }))
)
const RegCardDocument = lazy(() =>
  import('./RegCardDocument').then(module => ({
    default: module.RegCardDocument,
  }))
)
const RegistrationCardDocument = lazy(() =>
  import('../../BusinessInsightModule/BIDigitalDocument/RegistrationCardDocument').then(module => ({
    default: module.RegistrationCardDocument,
  }))
)
const DepositSlip = lazy(() =>
  import('./DepositSlip').then(module => ({
    default: module.DepositSlip,
  }))
)
const DepositRefund = lazy(() =>
  import('./DepositRefund').then(module => ({
    default: module.DepositRefund,
  }))
)
const CheckInRegCard = lazy(() =>
  import('./CheckInRegCard').then(module => ({
    default: module.CheckInRegCard,
  }))
)
// Due in
export const dueInCheckInUrl = `/frontdesk/duein/checkin/regcard`
export const dueInDepositReceiptUrl = `/frontdesk/duein/deposit/receipt`
export const dueInDepositPaymentVoucherUrl = `/frontdesk/duein/deposit/detail/paymentvoucher`
// Deposit
export const depositReceiptUrl = '/frontdesk/deposit/detail/receipt'
export const depositViewReceiptUrl = '/frontdesk/deposit/detail/view/receipt'
export const depositRefundReceiptUrl = '/frontdesk/deposit/refunddetail/receipt'
// booking advance payment
export const advancePaymentViewReceiptUrl =
  '/booking/advancepayment/view/receipt'

// due out
export const dueoutDepositReceiptUrl =
  '/frontdesk/dueout/deposit/detail.receipt'
// invoice
export const inhouseLateCheckoutUrl = `/frontdesk/inhouse/latecheckout/payment/invoice`
export const inhouseLateCheckoutFolioUrl = `/frontdesk/inhouse/latecheckout/payment/folio`
export const inhouseRoomUpgradeUrl = `/frontdesk/inhouse/roomupgrade/payment/invoice`
export const inhouseExtendStayUrl = `/frontdesk/inhouse/extendstay/payment/invoice`
export const GuestProfileUrl = `/guestprofile/guestfolio`
//Booking
export const BookingAdvancePaymentUrl = `/booking/advancepayment/folio`
//! Reg Card
export const inhouseAddGuestRegCardUrl = '/frontdesk/inhouse/addguest/regcard'
export const dueinAddGuestRegCardUrl = '/frontdesk/duein/addguest/regcard'
export const walkinAddGuestRegCardUrl = '/frontdesk/walkin/addguest/regcard'
export const DocumentRoutes = [
  {
    props: { exact: true, path: dueInCheckInUrl },
    component: <CheckInRegCard mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: dueInDepositReceiptUrl },
    component: (
      <Document mode={FrontDeskType.DueIn} type={DocumentType.Receipt} />
    ),
  },
  {
    props: { exact: true, path: dueInDepositPaymentVoucherUrl },
    component: (
      <DepositRefund
        mode={FrontDeskType.DueIn}
        type={DocumentType.RefundVoucher}
      />
    ),
  },

  {
    props: { exact: true, path: depositReceiptUrl },
    component: (
      <Document mode={FrontDeskType.Deposit} type={DocumentType.Receipt} />
    ),
  },
  {
    props: { exact: true, path: depositViewReceiptUrl },
    component: (
      <DepositSlip mode={FrontDeskType.Deposit} type={DocumentType.Receipt} />
    ),
  },
  {
    props: { exact: true, path: advancePaymentViewReceiptUrl },
    component: (
      <DepositSlip mode={'Advance Payment'} type={DocumentType.Receipt} />
    ),
  },

  {
    props: { exact: true, path: depositRefundReceiptUrl },
    component: (
      <DepositRefund
        mode={FrontDeskType.Deposit}
        type={DocumentType.RefundVoucher}
      />
    ),
  },
  {
    props: { exact: true, path: inhouseLateCheckoutUrl },
    component: (
      <Document mode={FrontDeskType.InHouse} type={DocumentType.Invoice} />
    ),
  },
  // {
  //   props: { exact: true, path: BookingAdvancePaymentUrl },
  //   component: (
  //     <Document mode={FrontDeskType.InHouse} type={DocumentType.Folio} />
  //   ),
  // },
  {
    props: { exact: true, path: inhouseLateCheckoutFolioUrl },
    component: (
      <Document mode={FrontDeskType.InHouse} type={DocumentType.Folio} />
    ),
  },
  {
    props: { exact: true, path: inhouseRoomUpgradeUrl },
    component: (
      <Document mode={FrontDeskType.RoomUpgrade} type={DocumentType.Invoice} />
    ),
  },
  {
    props: { exact: true, path: inhouseExtendStayUrl },
    component: (
      <Document mode={FrontDeskType.ExtendStay} type={DocumentType.Invoice} />
    ),
  },
  {
    props: { exact: true, path: GuestProfileUrl },
    component: (
      <Document mode={FrontDeskType.InHouse} type={DocumentType.Folio} />
    ),
  },
  {
    props: { exact: true, path: dueoutDepositReceiptUrl },
    component: (
      <Document mode={FrontDeskType.DueOut} type={DocumentType.Receipt} />
    ),
  },

  //! REG CARD

  // {
  //   props: { exact: true, path: inhouseAddGuestRegCardUrl },
  //   component: <RegCardDocument mode={FrontDeskType.InHouse} />,
  // },
  {
    props: { exact: true, path: inhouseAddGuestRegCardUrl },
    component: <RegistrationCardDocument mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: dueinAddGuestRegCardUrl },
    component: <RegistrationCardDocument mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: walkinAddGuestRegCardUrl },
    component: <RegistrationCardDocument mode={FrontDeskType.WalkIn} />,
  },
]
