export const handleObj = (guest, booking, guests) => {
  return {
    guest,
    booking,
    guests,
  }
}

export function not(a: number[], b: number[]) {
  return a.filter(value => b.indexOf(value) === -1)
}

export function intersection(a: number[], b: number[]) {
  return a.filter(value => b.indexOf(value) !== -1)
}

export function union(a: number[], b: number[]) {
  return [...a, ...not(b, a)]
}

export const PaymentMode = [
  {
    value: 'Cash',
    name: 'Cash',
  },
  {
    value: 'Card',
    name: 'Credit/Debit Card',
  },
  {
    value: 'CityLedger',
    name: 'CityLedger',
  },
  {
    value: 'Cheque',
    name: 'Cheque',
  },
  {
    value: 'BankTt',
    name: 'Bank TT',
  },
  {
    value: 'Online',
    name: 'Online',
  },

  {
    value: 'Voucher',
    name: 'Voucher',
  },
  {
    value: 'Other',
    name: 'Other',
  },




]

export const CheckOutAdjPaymentMode = [
  {
    value: 'Cash',
    name: 'Cash',
  },
  {
    value: 'Card',
    name: 'Credit/Debit Card',
  },

  {
    value: 'CityLedger',
    name: 'CityLedger',
  },

  {
    value: 'BankTt',
    name: 'Bank TT',
  },
  {
    value: 'Online',
    name: 'Online',
  },

  {
    value: 'Voucher',
    name: 'Voucher',
  },
  {
    value: 'Other',
    name: 'Other',
  },

]
export const FrontDeskType = {
  DueIn: 'Due In',
  WalkIn: 'Walk In',
  DueOut: 'Due Out',
  RoomAssignment: 'Room Assignment',
  InHouse: 'In House',
  Deposit: 'Deposit',
  RoomUpgrade: 'Room Upgrade',
  ExtendStay: 'Extend Stay',
}
export const DepositType = [
  { value: 'SecurityDeposit', name: 'Security Deposit' },
  { value: 'KeyCardDeposit', name: 'Key Card Deposit' },
]
export const DocumentType = {
  Receipt: 'Receipt',
  RegCard: 'Registration Card',
  RefundVoucher: 'Refund Voucher',
  Invoice: 'Invoice',
  Folio: 'Folio',
}

export const CardType = [
  'Visa Card',
  'Master Card',
  'American Express Card',
  'Diners Card',
  'JCB Card',
  'UNION PAY',
  'Debit Card',
  'E-Wallet',
]
export const cardType = [
  { name: 'Visa Card', value: 'VisaCard' },
  { name: 'Master Card', value: 'MasterCard' },
  { name: 'American Express Card', value: 'American Express Card' },
  { name: 'Diners Card', value: 'DinersCard' },
  { name: 'JCB Card', value: 'JCBCard' },
  { name: 'UNION PAY', value: 'UNION PAY' },
  { name: 'Debit Card', value: 'DebitCard' },
  { name: 'E-Wallet', value: 'EWallet' },
]
export const ChargesType = {
  LateCheckOut: 'Late CheckOut',
  ExtendStay: 'Extend Stay',
  RoomUpgrade: 'Room Upgrade',
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  var blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const KeyCardOption = [
  {
    label: 'New',
    value: 'N',
  },
  {
    label: 'Duplicate',
    value: 'D',
  },
  {
    label: 'Erase',
    value: 'E',
  },
]
