import React from 'react'
import { Redirect } from 'react-router-dom'
import authenticationRoutes from 'containers/AccountModule/AccountRoutes'
import settingsRoutes from 'containers/SettingsModule/SettingsRoutes'
import homeRoutes from 'containers/HomeModule/HomeRoutes'
import bookingRoutes from 'containers/BookingModule/BookingRoutes'
import frontDeskRoutes from 'containers/FrontDeskModule/FrontDeskRoutes'
import DigitalMarketingRoutes from 'containers/DigitalMarketingModule/DigitalMarketingRoutes'
import travelAgentRoutes from 'containers/TravelAgentModule/TravelAgentRoutes'
import CorporateRoutes from 'containers/CorporateModule/CorporateRoutes'
import RoomServiceRoutes from 'containers/RoomServicesModule/RoomServiceRoutes'
import SystemAdminRoutes from 'containers/SystemAdminModule/SystemAdminRoutes'
import HousekeepingRoutes from 'containers/HousekeepingModule/HousekeepingRoutes'
import GuestProfileRoutes from 'containers/GuestProfileModule/GuestProfileRoutes'
import ServiceRequestRoutes from 'containers/ServiceRequestModule/ServiceRequestRoute'
import GLExportRoutes from 'containers/GLInterfaceModule/GLExportRoutes'
import BusinessInsightRoutes from 'containers/BusinessInsightModule/BusinessRoute'
import cityLedgerRoutes from 'containers/CityLedgerModule/CityLedgerRoutes'
import PackageRedemptionRoutes from 'containers/PackageRedemptionModule/PackageRedemptionRoutes'
import CheckoutAdjustmentRoutes from 'containers/CheckoutAdjustmentModule/CheckoutAdjustmentRoutes'
import Q3FInterfaceRoutes from 'containers/Q3FInterfaceModule/Q3FInterfaceRoutes'
import CommonSettingsRoutes from 'containers/CommonSettingsModule/CommonSettingsRoutes'
import CompanyRoutes from 'containers/CommonSettingsModule/CompanySetup/CompanyRoutes'


const StartRoute = [
  {
    props: { exact: true, path: '/home' },
    component: <Redirect to="/home" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...homeRoutes,
  ...settingsRoutes,
  ...bookingRoutes,
  ...frontDeskRoutes,
  ...HousekeepingRoutes,
  ...CorporateRoutes,
  ...GuestProfileRoutes,
  ...RoomServiceRoutes,
  ...bookingRoutes,
  ...travelAgentRoutes,
  ...ServiceRequestRoutes,
  ...DigitalMarketingRoutes,
  ...PackageRedemptionRoutes,
  ...CheckoutAdjustmentRoutes,
  ...GLExportRoutes,
  ...BusinessInsightRoutes,
  ...cityLedgerRoutes,
  ...Q3FInterfaceRoutes,
  ...CommonSettingsRoutes,
  ...CompanyRoutes,
  // ...SystemAdminRoutes,
  ...authenticationRoutes,
  ...StartRoute,
  ...errorRoute,
]

export default routes
