import { DueInServiceRequestRoutes } from './ServiceRequest/ServiceRequestRoutes'
import React, { lazy } from 'react'
import { DepositRoutes } from './Deposit/DepositRoutes'
import { DocumentRoutes } from './Document/DocumentRoutes'
import { DueInRoutes } from './DueIn/DueInRoutes'
import { DueOutRoutes } from './DueOut/DueOutRoutes'
import { InChargesRoutes } from './IncidentalCharges/IncidentalChargesRoutes'
import { InHouseRoutes } from './InHouse/InHouseRoutes'
import { RoomAssignmentRoutes } from './RoomAssignment/RoomAssignmentRoutes'
import { WalkInRoutes } from './WalkIn/WalkInRoutes'
import { GroupCheckInRoutes } from './GroupCheckIn/GroupCheckInRoutes'
import { PaymentRoutes } from './Payment/PaymentRoutes'
import { NonGuestFolioRoutes } from './NonGuestFolio/NonGuestFolioRoutes'
import { BillingScheduleRoutes } from './BillingSchedule/BillingScheduleRoutes'
import { AdvancePaymentRoutes } from './AdvancePayment/AdvancePaymentRoutes'
import { ChangeRoomRoutes } from './ChangeRoom/ChangeRoomRoutes'
import { ServiceRequestRoutes } from 'containers/ServiceRequestModule/ServiceRequestRoute'
import { SuspendFolioRoutes } from './SuspendFolio/SuspendFolioRoutes'
const FrontDeskListing = lazy(() =>
  import('./FrontDeskListing').then(module => ({
    default: module.FrontDeskListing,
  }))
)
const ContactTracing = lazy(() =>
  import('./ContactTracing/ContactTracing').then(module => ({
    default: module.ContactTracing,
  }))
)
const ContactDetail = lazy(() =>
  import('./ContactTracing/ContactDetail').then(module => ({
    default: module.ContactDetail,
  }))
)
export const frontDesk = '/frontdesk'
const frontDeskRoutes = [
  {
    props: { exact: true, path: `${frontDesk}` },
    component: <FrontDeskListing />,
  },
  {
    props: { exact: true, path: '/frontdesk/contacttracing' },
    component: <ContactTracing />,
  },
  {
    props: { exact: true, path: '/frontdesk/contacttracing/detail' },
    component: <ContactDetail />,
  },
  ...AdvancePaymentRoutes,
  ...DueInRoutes,
  ...WalkInRoutes,
  ...RoomAssignmentRoutes,
  ...InHouseRoutes,
  ...InChargesRoutes,
  ...DueOutRoutes,
  ...DepositRoutes,
  ...DocumentRoutes,
  ...BillingScheduleRoutes,
  ...DueInServiceRequestRoutes,
  ...ServiceRequestRoutes,
  ...NonGuestFolioRoutes,
  ...NonGuestFolioRoutes,
  ...SuspendFolioRoutes,
  ...GroupCheckInRoutes,
  ...ChangeRoomRoutes,
  ...PaymentRoutes,
]

export default frontDeskRoutes
