import React, { lazy } from 'react'
import { LedgerType, LedgerTypeArr } from './Models'

const DebtorLedgerSubmenu = lazy(() =>
  import('./DebtorLedgerSubmenu').then(module => ({
    default: module.DebtorLedgerSubmenu,
  }))
)

// const GeneralDebtorLedgerListing = lazy(() =>
//   import(
//     '../DebtorAccountModule/DebtorLedgerListing/GeneralDebtorLedgerListing'
//   ).then(module => ({
//     default: module.GeneralDebtorLedgerListing,
//   }))
// )
const DebtorList = lazy(() =>
  import('./DebtorList/DebtorList').then(module => ({
    default: module.DebtorList,
  }))
)

const DebtorForm = lazy(() =>
  import('./DebtorForm/DebtorForm').then(module => ({
    default: module.DebtorForm,
  }))
)
const DebtorDetail = lazy(() =>
  import('./DebtorDetail/DebtorDetail').then(module => ({
    default: module.DebtorDetail,
  }))
)
const DebtorDocument = lazy(() =>
  import('./DebtorDocument/DebtorDocument').then(module => ({
    default: module.DebtorDocument,
  }))
)

// const DebtorInvoice = lazy(() =>
//   import('./DebtorDocument/DebtorInvoice').then(module => ({
//     default: module.DebtorInvoice,
//   }))
// )

const DebtorDocumentNew = lazy(() =>
  import('./DebtorDocument/DebtorDocumentNew').then(module => ({
    default: module.DebtorDocumentNew,
  }))
)

const DebtorGroupDocument = lazy(() =>
  import('./DebtorDocument/DebtorGroupDocument').then(module => ({
    default: module.DebtorGroupDocument,
  }))
)

//new 8/8/22
const CityLedgerTransaction = lazy(() =>
  import('./DebtorList/CityLedgerTransaction/CityLedgerTransaction').then(
    module => ({
      default: module.CityLedgerTransaction,
    })
  )
)
const GroupCityLedgerTransaction = lazy(() =>
  import('./DebtorList/CityLedgerTransaction/GroupCityLedgerTransaction').then(
    module => ({
      default: module.GroupCityLedgerTransaction,
    })
  )
)
const DebtorReceipt = lazy(() =>
  import('./DebtorDocument/DebtorReceipt').then(module => ({
    default: module.DebtorReceipt,
  }))
)
const DebtorRefund = lazy(() =>
  import('./DebtorDocument/DebtorRefund').then(module => ({
    default: module.DebtorRefund,
  }))
)

const debtorAccountRoutes = [
  //..........Submenu..........//
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account',
    },
    component: <DebtorLedgerSubmenu />,
  },

  //..........Listing..........//
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/invoices',
    },
    component: <DebtorList ledgerType={LedgerType.Invoices} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/groupinvoices',
    },
    component: <GroupCityLedgerTransaction LedgerTypeArr={'cityLtrx'} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/debit',
    },
    component: <DebtorList ledgerType={LedgerType.DebitNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/credit',
    },
    component: <DebtorList ledgerType={LedgerType.CreditNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/receipts',
    },
    component: <DebtorList ledgerType={LedgerType.OfficialReceipt} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/refunds',
    },
    component: <DebtorList ledgerType={LedgerType.Refund} />,
  },
  //...... Form///

  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/invoices/add',
    },
    component: <DebtorForm ledgerType={LedgerType.Invoices} formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/CityLedgerTransaction',
    },
    component: <CityLedgerTransaction LedgerTypeArr={'cityLtrx'} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/debit/add',
    },
    component: <DebtorForm ledgerType={LedgerType.DebitNotes} formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/credit/add',
    },
    component: (
      <DebtorForm ledgerType={LedgerType.CreditNotes} formMode="add" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/receipts/add',
    },
    component: (
      <DebtorForm ledgerType={LedgerType.OfficialReceipt} formMode="add" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/refunds/add',
    },
    component: <DebtorForm ledgerType={LedgerType.Refund} formMode="add" />,
  },
  // Edit
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/invoices/edit',
    },
    component: <DebtorForm ledgerType={LedgerType.Invoices} formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/debit/edit',
    },
    component: (
      <DebtorForm ledgerType={LedgerType.DebitNotes} formMode="edit" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/credit/edit',
    },
    component: (
      <DebtorForm ledgerType={LedgerType.CreditNotes} formMode="edit" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/receipts/edit',
    },
    component: (
      <DebtorForm ledgerType={LedgerType.OfficialReceipt} formMode="edit" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/refunds/edit',
    },
    component: <DebtorForm ledgerType={LedgerType.Refund} formMode="edit" />,
  },

  //Detail;
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/invoices/detail',
    },
    component: <DebtorDetail ledgerType={LedgerType.Invoices} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/debit/detail',
    },
    component: <DebtorDetail ledgerType={LedgerType.DebitNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/credit/detail',
    },
    component: <DebtorDetail ledgerType={LedgerType.CreditNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/receipts/detail',
    },
    component: <DebtorDetail ledgerType={LedgerType.OfficialReceipt} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/refunds/detail',
    },
    component: <DebtorDetail ledgerType={LedgerType.Refund} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/groupinvoices/detail',
    },
    component: <DebtorDetail ledgerType={LedgerType.GroupInvoices} />,
  },
  //Document;
  // {
  //   props: {
  //     exact: true,
  //     path: '/cityledger/debtor/account/invoices/document',
  //   },
  //   component: <DebtorInvoice ledgerType={LedgerType.Invoices} />,
  // },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/invoices/document',
    },
    component: <DebtorDocumentNew ledgerType={LedgerType.Invoices} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/groupinvoices/document',
    },
    component: <DebtorGroupDocument ledgerType={LedgerType.Invoices} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/debit/document',
    },
    component: <DebtorDocumentNew ledgerType={LedgerType.DebitNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/credit/document',
    },
    component: <DebtorDocumentNew ledgerType={LedgerType.CreditNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/receipts/document',
    },
    component: <DebtorReceipt ledgerType={LedgerType.OfficialReceipt} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/refunds/document',
    },
    component: <DebtorRefund ledgerType={LedgerType.Refund} />,
  },

  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/transactions/invoices',
    },
    component: <DebtorList ledgerType={LedgerType.Invoices} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/transactions/debit',
    },
    component: <DebtorList ledgerType={LedgerType.DebitNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/transactions/credit',
    },
    component: <DebtorList ledgerType={LedgerType.CreditNotes} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/transactions/receipts',
    },
    component: <DebtorList ledgerType={LedgerType.OfficialReceipt} />,
  },
  {
    props: {
      exact: true,
      path: '/cityledger/debtor/account/transactions/refunds',
    },
    component: <DebtorList ledgerType={LedgerType.Refund} />,
  },
]

export default debtorAccountRoutes
